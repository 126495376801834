import { Injectable, Injector } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppContextService {
  previousUrl: string;

  constructor(private injector: Injector) {}

  init(): void {
    const router = this.injector.get(Router);

    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.previousUrl = event.url;
    });
  }
}
